import { useEffect, useMemo } from "react";
import { useAtom, useAtomValue } from "jotai";
import { Loader } from "lucide-react";

import { AccountType } from "shared/types";
import { cn } from "shared/lib";
import { noticeboardDataVar, showReportVar } from "@/variables/globalVar";
import { reportLoadingProgressIndicatorItemsMap } from "./lib";

import {
  useAssessmentLights,
  useExpertMapConfig,
  useMediaQuery,
} from "shared/hooks";
import { useAccount, useLatestMessage, useMessages } from "@/hooks";

import { AssessmentReportFastEnquiry, NextSteps } from "shared/components";
import { ConfirmReport } from "@/components/ConfirmReport";
import { ReportLoaderContainer } from "@/components/assessmentReport/ReportLoaderContainer";
import { ReportWarningAccordion } from "@/components/ReportWarningAccordion";
import { LotPanel } from "@/components/lot";
import { ProposalPanel } from "@/components/proposal";
import { RenderWizardStep } from "./RenderWizardStep";
import { OffTopicPanel } from "./OffTopicPanel";

export function Wizard() {
  const { conversation } = useAccount();
  const { messages } = useMessages();
  const latestUserMessage = useLatestMessage({
    senderAccountType: AccountType.USER,
  });
  const latestAssistantMessage = useLatestMessage({
    senderAccountType: AccountType.ASSISTANT,
  });
  const noticeboardData = useAtomValue(noticeboardDataVar);
  const assessmentLights = useAssessmentLights(conversation, noticeboardData);

  const [showReport, setShowReportVar] = useAtom(showReportVar);

  const shouldRenderSideBySide = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const expertMapConfig = useExpertMapConfig(conversation);
  const expertMap = expertMapConfig?.["enquiry"];

  const showLoadingIndicator = !conversation || !latestAssistantMessage;

  const conversationEnded = [
    "ready_for_assessment",
    "report_ready",
    "amendment",
    "saved_report",
    "sent_to_council",
    "off_topic",
  ].includes(conversation?.phase || "");

  const offTopic = conversation?.phase === "off_topic";

  const reportGenerating = conversation?.phase === "ready_for_assessment";
  const reportReady = useMemo(() => {
    const reportReadyExperts = expertMap?.report_ready || [];

    return (
      ["report_ready", "amendment", "saved_report", "sent_to_council"].includes(
        conversation?.phase || "",
      ) ||
      (conversation?.phase === "ready_for_assessment" &&
        reportReadyExperts.every((expertName) => expertName in noticeboardData))
    );
  }, [conversation?.phase, expertMap, noticeboardData]);

  const reportConfirmed =
    showReport.conversationId === conversation?.id && showReport.visible;

  const showConfirmReport =
    (reportGenerating || reportReady) && !reportConfirmed;

  const setShowReportState = (visible: boolean) => {
    setShowReportVar({ visible, conversationId: conversation?.id || "" });
    localStorage.setItem(
      "showReport",
      JSON.stringify({ visible, conversationId: conversation?.id || "" }),
    );
  };

  useEffect(() => {
    setShowReportVar(JSON.parse(localStorage.getItem("showReport") || "{}"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLotAndProposalColumn = () => {
    return (
      <div className="flex min-w-[360px] max-w-[480px] flex-col">
        <LotPanel />

        <div className="min-h-6" />

        <ProposalPanel />

        <div className="min-h-6 md:min-h-12" />
      </div>
    );
  };

  return (
    <section
      aria-label="chat"
      className="flex h-full min-h-0 flex-col"
      role="region"
    >
      {showLoadingIndicator ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader className="text-textSubdued h-[48px] w-[48px] animate-spin" />
        </div>
      ) : null}

      {!showLoadingIndicator && !conversationEnded ? (
        <div className="relative flex w-full grow flex-col items-center overflow-y-auto p-6 pb-0 md:p-12 md:pb-0">
          <RenderWizardStep
            messages={messages}
            message={latestAssistantMessage}
          />
        </div>
      ) : null}

      {!showLoadingIndicator && offTopic ? (
        <div className="h-full p-6">
          <OffTopicPanel />
        </div>
      ) : null}

      {!showLoadingIndicator &&
      showConfirmReport &&
      (reportGenerating || reportReady) ? (
        <div className="bg-interfaceBgDefault flex h-full justify-center gap-12 overflow-y-auto p-6 md:p-12">
          <ConfirmReport
            className="w-full md:max-w-[700px]"
            setShowReport={setShowReportState}
          />
          {shouldRenderSideBySide ? renderLotAndProposalColumn() : null}
        </div>
      ) : null}

      {!showLoadingIndicator &&
      !showConfirmReport &&
      reportGenerating &&
      !reportReady ? (
        <div className="bg-interfaceBgDefault flex h-full justify-center gap-12 overflow-y-auto p-6 md:p-12">
          <ReportLoaderContainer
            className="w-full md:max-w-[700px]"
            latestUserMessage={latestUserMessage}
            noticeboardData={noticeboardData}
            progressIndicatorItemMap={reportLoadingProgressIndicatorItemsMap}
          />
          {shouldRenderSideBySide ? renderLotAndProposalColumn() : null}
        </div>
      ) : null}

      {!showLoadingIndicator && !showConfirmReport && reportReady ? (
        <div className="bg-interfaceBgDefault flex h-full justify-center gap-12 overflow-y-auto p-6 md:p-12">
          <div
            className={cn(
              "flex h-full flex-col gap-y-6",
              shouldRenderSideBySide ? "max-w-[700px]" : "",
            )}
          >
            <AssessmentReportFastEnquiry
              conversation={conversation}
              expertMap={expertMap}
              noticeboardData={noticeboardData}
            />

            <NextSteps
              assessmentLights={assessmentLights}
              conversation={conversation}
            />

            <ReportWarningAccordion />

            <div className="min-h-6 w-1 md:min-h-12" />
          </div>
          {shouldRenderSideBySide ? renderLotAndProposalColumn() : null}
        </div>
      ) : null}
    </section>
  );
}
