import { useEffect, useState, useRef, type ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import { FileText, HandIcon, X } from "lucide-react";
import { useReactToPrint } from "react-to-print";
import { useSetAtom } from "jotai";

import { type Message } from "shared/apiClient";
import {
  createMDWrapper,
  titleCase,
  type Noticeboard,
  type NoticeboardResult,
} from "shared/lib";
import { conversationOverrideVar } from "@/variables/globalVar";

import { useGetPublicConversation } from "shared/apiHooks";
import { useAssessmentLights, useExpertMapConfig } from "shared/hooks";
import { useAccount } from "@/hooks";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AssessmentReport,
  AssessmentReportFastEnquiry,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  NextSteps,
} from "shared/components";
import { MyLotCompassIcon, MyLotPdfIcon } from "@/components/icons";
import { ReportWarningAccordion } from "@/components/ReportWarningAccordion";
import { TextMessageWidget } from "@/components/chat/widgets/TextMessageWidget";
import { CopyLinkButton } from "@/components/CopyLinkButton";
import { HelpMessage } from "@/components/HelpMessage";
import { LocalisedLogo } from "@/components/LocalisedLogo";

export function PublicViewPage() {
  const [searchParams] = useSearchParams();
  const identifier = searchParams.get("id") || "";

  const componentRef = useRef(null);

  const [noticeboardData, setNoticeboardData] = useState<Noticeboard>({});
  const [messages, setMessages] = useState<Array<Message>>([]);

  const [reportWarningAccordionValue, setReportWarningAccordionValue] =
    useState([""]);
  const [accordionValue, setAccordionValue] = useState([""]);

  const [isValid, setIsValid] = useState(false);

  const setConversationOverride = useSetAtom(conversationOverrideVar);

  const { conversation } = useAccount();

  const assessmentLights = useAssessmentLights(conversation, noticeboardData);

  const expertMapConfig = useExpertMapConfig(conversation);
  const expertMap = expertMapConfig?.["enquiry"];

  const { data: publicConversationResponse } = useGetPublicConversation({
    path: { conversation_identifier: identifier.toLowerCase() },
  });

  useEffect(() => {
    if (publicConversationResponse?.data) {
      console.log(
        "<PublicViewPage> publicConversationResponse",
        publicConversationResponse.data,
      );

      setConversationOverride(publicConversationResponse.data);

      setMessages(publicConversationResponse.data.messages || []);

      const noticeboardArray = publicConversationResponse.data
        .noticeboard as unknown as Array<NoticeboardResult>;
      setNoticeboardData(
        noticeboardArray.reduce((noticeboard, result) => {
          noticeboard[result.expert_name] = result;
          return noticeboard;
        }, {} as Noticeboard),
      );

      setIsValid(true);
    }
  }, [publicConversationResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("<PublicViewPage> noticeboardData", noticeboardData);
  }, [noticeboardData]);

  useEffect(() => {
    if (conversation?.address) {
      document.title = `Planning Enquiry Report for ${titleCase(conversation.address)}`;
    }
  }, [conversation]);

  const beforePrint = () => {
    setReportWarningAccordionValue(["item"]);
    setAccordionValue(["lot", "plans", "conversation"]);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    documentTitle: `MyLot Report`,
    onBeforeGetContent: () => beforePrint(),
    onPrintError: () => alert("there is an error when printing"),
  });

  const Header = () => (
    <div className="border-b-interactiveBorder flex items-center justify-between border-b bg-[#F3F2F0] py-3 pl-4 pr-3 md:h-[60px] md:py-0 md:pl-8 md:pr-9">
      <div className="flex h-[32.51px] w-[73.19px] cursor-pointer items-center justify-center md:h-[36.57px] md:w-[82.34px]">
        <LocalisedLogo />
      </div>

      <div className="flex flex-row">
        <Dialog>
          <DialogTrigger tabIndex={0}>
            <div className="border-interactiveBorder bg-interfaceWhite text-textSubdued md:rounded-5xl flex flex-row items-center justify-between rounded border px-3 py-1.5 text-xs leading-[14px]">
              <HandIcon className="text-textSubdued mr-0.5 h-[16px] w-[16px]" />
              Help
            </div>
          </DialogTrigger>
          <DialogContent
            className="max-w-[560px] rounded-3xl border-0 p-0"
            hideCloseButton
          >
            <div className="bg-interfaceWhite flex flex-col gap-[16px] rounded-[20px] p-[24px] shadow-sm md:px-[60px] md:py-[48px]">
              <DialogClose className="absolute right-6 top-6">
                <X className="text-textPrimary h-[24px] w-[24px]" />
              </DialogClose>
              <h2 className="text-textPrimary flex cursor-pointer items-center gap-2 text-[28px] font-semibold leading-[1.2]">
                <HandIcon className="text-textPrimary h-[24px] w-[24px]" />
                Help
              </h2>
              <div className="text-textPrimary text-lg leading-[1.2222]">
                <HelpMessage />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );

  function getOverlays(): Array<ReactElement> {
    const el: Array<ReactElement> = [];

    const overlays = conversation?.address_data?.overlays || [];
    if (overlays.length > 0) {
      overlays.forEach((overlay, index) => {
        el.push(
          <li
            key={index + "lot-overlay"}
            className="text-textPrimary list-none text-lg leading-[22px]"
          >
            {titleCase(overlay.name || "")}{" "}
            {"(Schedule " + overlay.schedule + ")"}
          </li>,
        );
      });
    } else {
      el.push(
        <li
          key="overlayNone"
          className="text-textPrimary text-lg leading-[22px]"
        >
          🌱 There are no overlays associated with this address.
        </li>,
      );
    }

    return el;
  }

  const PublicLot = () => {
    const addressData = conversation?.address_data;
    const zone = addressData?.zones?.[0];

    return (
      <div>
        <h2 className="mb-2 text-lg font-semibold leading-[22px]">
          {titleCase(conversation?.address || "")}
        </h2>
        <img
          className="mb-5 h-[270px] w-full rounded object-cover"
          src={addressData?.satellite_image_url || ""}
          alt="Boundary map"
        />
        <h2 className="mb-2 text-lg font-semibold leading-[22px]">Lot size</h2>
        <p className="mb-2 text-lg leading-[22px]">
          {addressData?.lot_size_sqm ? `${addressData?.lot_size_sqm}m` : "N/A"}
          <span className="align-text-top text-[8px]">2</span>
        </p>
        <div className="border-interfaceBorder w-full border-b pt-5" />
        <h2 className="mt-5 text-lg font-semibold leading-[22px]">Zone</h2>
        <h3 className="text-textSubdued mb-3 text-base leading-5">
          Such as residential, farming, industrial
        </h3>
        <p className="text-textPrimary text-lg leading-[22px]">
          {titleCase(zone?.name || "") + " (Schedule " + zone?.schedule + ")"}
        </p>
        <div className="border-interfaceBorder w-full border-b pt-5" />
        <h2 className="mt-5 text-lg font-semibold leading-[22px]">Overlays</h2>
        <h3 className="text-textSubdued mb-3 text-base leading-5">
          Such as heritage, erosion, bushfire management
        </h3>
        <div className="text-textPrimary text-lg leading-[22px]">
          {getOverlays()}
        </div>
      </div>
    );
  };

  function getFactsAndQuestions(): string {
    return noticeboardData?.project_details?.raw_output || "";
  }

  function getProposedLandUse(): string {
    return (
      noticeboardData?.land_use_terms?.parsed_output?.proposed_land_use_term ||
      ""
    );
  }

  function getExistingLandUse(): string {
    return (
      noticeboardData?.land_use_terms?.parsed_output?.existing_land_use_term ||
      ""
    );
  }

  function getChangeOfLandUse(): string {
    let returnStr: string = "🤔 Unsure";
    if (
      noticeboardData?.land_use_terms?.parsed_output?.proposed_land_use_term &&
      noticeboardData?.land_use_terms?.parsed_output?.existing_land_use_term
    ) {
      if (getProposedLandUse() === "N/A") {
        returnStr = returnStr = "❌ No";
      } else {
        returnStr = "✅ Yes";
      }
    }
    return returnStr;
  }

  function getIsCommercial(): string {
    let returnStr: string = "🤔 Unsure";
    if (noticeboardData?.development_type?.parsed_output?.public_use) {
      if (noticeboardData.development_type.parsed_output.public_use === "Yes") {
        returnStr = "✅ Yes";
      } else if (
        noticeboardData.development_type.parsed_output.public_use === "No"
      ) {
        returnStr = "❌ No";
      }
    }
    return returnStr;
  }

  function getBuildingAndWorks(): string {
    let returnStr: string = "🤔 Unsure";
    if (noticeboardData?.development_type?.parsed_output?.buildings_and_works) {
      if (
        noticeboardData.development_type.parsed_output.buildings_and_works ===
        "Yes"
      ) {
        returnStr = "✅ Yes";
      } else if (
        noticeboardData.development_type.parsed_output.buildings_and_works ===
        "No"
      ) {
        returnStr = "❌ No";
      }
    }
    return returnStr;
  }

  const PublicPlans = () => (
    <div>
      <div className="border-interfaceBorder border-b pb-5">
        <h2 className="mb-3 text-start text-lg font-semibold leading-[22px]">
          Projects
        </h2>
        {createMDWrapper(getFactsAndQuestions())}
      </div>

      <div className="flex flex-row justify-center pb-4 pt-5">
        <div className="w-full">
          <h2 className="text-textPrimary text-lg font-semibold leading-[22px]">
            Existing land use
          </h2>
          <h3 className="text-textSubdued mb-4 text-xs leading-[14px]">
            How is the lot currently being used?
          </h3>
          {getExistingLandUse() === "" ? (
            <p className="text-textPrimary text-base leading-5">🤔 Unsure</p>
          ) : (
            <div>
              <label className="w-min text-nowrap rounded-xl border-[1px] border-[#D2B7FF] bg-[#F8F3FF] px-2 py-0.5 text-sm font-medium leading-4 text-[#3A0075]">
                {getExistingLandUse()}
              </label>
            </div>
          )}
        </div>

        <div className="border-interfaceBorder w-full border-l pl-6">
          <h2 className="text-textPrimary mb-0.5 text-lg font-semibold leading-[22px]">
            Proposed land use
          </h2>
          <h3 className="text-textSubdued mb-3 text-xs leading-[14px]">
            How are you planning to use the lot?
          </h3>

          {getProposedLandUse() === "" || getProposedLandUse() === "N/A" ? (
            <div>
              <p className="text-textPrimary text-base leading-5">
                {getProposedLandUse() === "N/A" ? "No change" : "🤔 Unsure"}
              </p>
            </div>
          ) : (
            <div>
              <label className="w-min text-nowrap rounded-xl border-[1px] border-[#D2B7FF] bg-[#F8F3FF] px-2 py-0.5 text-sm font-medium leading-4 text-[#3A0075]">
                {getProposedLandUse()}
              </label>
            </div>
          )}
        </div>
      </div>
      <p className="text-textSubdued text-xs leading-[14px]">
        The existing and proposed land uses are determined by the way you’ve
        described your lot and proposal. Land use terms are defined in the
        planning scheme.
      </p>

      <div className="pt-6">
        <table className="w-full">
          <tbody className="w-full">
            <tr className="flex w-full flex-row items-center">
              <th className="border-interfaceBorder text-textPrimary w-full justify-start border-r py-2 pr-3 text-start text-base font-semibold leading-5 md:w-[360px] md:py-3">
                <h2>Impact to land use</h2>
                <h3 className="text-textSubdued text-xs font-normal leading-[14px]">
                  Is the lot being used in a new way?
                </h3>
              </th>
              <th className="text-textPrimary w-[100px] px-3 py-2 text-start text-base font-normal leading-5 md:w-full md:p-3">
                {getChangeOfLandUse()}
              </th>
            </tr>
            <tr className="border-interfaceBorder flex w-full flex-row items-center border-t">
              <th className="border-interfaceBorder text-textPrimary w-full border-r py-2 pr-3 text-start text-base font-semibold leading-5 md:w-[360px] md:py-3">
                <h2>Public use</h2>
                <h3 className="text-textSubdued text-xs font-normal leading-[14px]">
                  Do the plans involve selling goods or services?
                </h3>
              </th>
              <th className="text-textPrimary w-[100px] px-3 py-2 text-start text-base font-normal leading-5 md:w-full md:p-3">
                {getIsCommercial()}
              </th>
            </tr>
            <tr className="border-interfaceBorder flex w-full flex-row items-center border-t">
              <th className="border-interfaceBorder text-textPrimary w-full border-r py-2 pr-3 text-start text-base font-semibold leading-5 md:w-[360px] md:py-3">
                <h2>Building and works</h2>
                <h3 className="text-textSubdued text-xs font-normal leading-[14px]">
                  Do the plans involve activity like construction or earthworks?
                </h3>
              </th>
              <th className="text-textPrimary w-[100px] px-3 py-2 text-start text-base font-normal leading-5 md:w-full md:p-3">
                {getBuildingAndWorks()}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  const PublicConversation = () => (
    <div className="h-full min-h-0 grow">
      <div className="mb-0.5 flex flex-col pr-5 md:pr-20">
        <div className="text-textSubdued text-xs leading-[18px]">
          {new Intl.DateTimeFormat("en-US", {
            timeStyle: "short",
          }).format(new Date(conversation?.created_at || ""))}
        </div>
        <div className="text-textPrimary self-start rounded-lg bg-[#F5F5F5] px-5 py-2 text-lg leading-[22px]">
          Thanks, I’ve pulled up the lot details for{" "}
          {titleCase(conversation?.address || "")}. You can find a summary in
          the <span className="font-semibold">Lot tab</span>.
          <br />
          <br />
          Now I’ll guide you through a few questions. You can also ask me a
          question or just say “I don’t know”.
        </div>
      </div>
      {messages?.map((message, index) => (
        <div key={index} className="mb-3 last:mb-0">
          <TextMessageWidget
            message={message}
            animated={false}
            hideTime={index <= 0}
            isPublic
          />
        </div>
      ))}
    </div>
  );

  const renderAssessmentReport = () => {
    if (expertMap) {
      return (
        <AssessmentReportFastEnquiry
          conversation={conversation}
          expertMap={expertMap}
          noticeboardData={noticeboardData}
        />
      );
    }
    return (
      <AssessmentReport
        conversation={conversation}
        expertMap={expertMap}
        flowType={undefined}
        noticeboardData={noticeboardData}
      />
    );
  };

  return (
    <div className="bg-interfaceBgDefault text-textPrimary h-dvh w-screen">
      {!isValid && <div>Loading</div>}
      {isValid && (
        <div ref={componentRef} className="h-full w-full overflow-auto">
          <Header />
          <section className="bg-interfaceWhite md:bg-interfaceBgDefault px-5 py-5 md:px-10 md:py-8">
            <h1 className="mb-1 flex flex-col text-[32px] leading-10 md:flex-row">
              <span className="font-semibold">Planning enquiry report for</span>
              <div className="md:ml-2">
                <strong>{`${titleCase(conversation?.address || "")}`}</strong>
              </div>
            </h1>
            <div className="mb-6 flex flex-col items-start md:flex-row md:items-center">
              <h2 className="mb-1 mr-3 flex flex-row items-center text-base leading-5 md:mb-0">
                <div className="mr-0.5 h-5 w-5">
                  <FileText className="h-5 w-5" />
                </div>
                {identifier?.toUpperCase()}
              </h2>
              <h2 className="border-interactiveBorder mb-1 flex flex-row items-center pr-3 text-base leading-5 md:mb-0 md:border-r">
                <div className="mr-0.5 h-5 w-5">
                  <MyLotCompassIcon />
                </div>
                {noticeboardData?.development_type?.parsed_output
                  ?.development_activity ?? ""}
              </h2>
              <div className="flex flex-row">
                <button
                  onClick={handlePrint}
                  className="text-textLink hover:text-textLinkHover mr-3 flex flex-row text-base leading-5 underline md:ml-3 md:mr-0 print:hidden"
                >
                  <div className="mr-0.5 h-5 w-5">
                    <MyLotPdfIcon className="text-textLink hover:text-textLinkHover" />
                  </div>
                  Save as PDF
                </button>
                <div className="border-interactiveBorder border-l pl-3 md:border-l-0">
                  <CopyLinkButton link={window.location.href} />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-x-8 md:flex-row print:flex-col">
              <div className="w-full">
                {renderAssessmentReport()}

                <div className="pt-4 md:pt-8">
                  <NextSteps
                    assessmentLights={assessmentLights}
                    conversation={conversation}
                  />
                </div>
              </div>
              <div className="w-full break-inside-avoid print:pt-6">
                <div
                  className={`border-interactiveBorder bg-interfaceWhite mb-6 rounded border`}
                >
                  <Accordion
                    type="multiple"
                    value={accordionValue}
                    onValueChange={setAccordionValue}
                  >
                    <AccordionItem
                      value="lot"
                      className={`border-interactiveBorder`}
                    >
                      <AccordionTrigger className="px-6 py-4 text-2xl font-semibold leading-7">
                        Lot
                      </AccordionTrigger>
                      <AccordionContent className="px-6 pb-6">
                        <PublicLot />
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem
                      value="plans"
                      className={`border-interactiveBorder`}
                    >
                      <AccordionTrigger className="px-6 py-4 text-2xl font-semibold leading-7">
                        Plans
                      </AccordionTrigger>
                      <AccordionContent className="px-6 pb-6">
                        <PublicPlans />
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="conversation" className="border-b-0">
                      <AccordionTrigger className="px-6 py-4 text-2xl font-semibold leading-7">
                        Conversation
                      </AccordionTrigger>
                      <AccordionContent className="px-6 pb-6">
                        <PublicConversation />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="break-inside-avoid print:pt-6">
                  <ReportWarningAccordion
                    isPublic={true}
                    accordionValueProp={reportWarningAccordionValue}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
