import { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { HandIcon, RotateCcw, X, LogOut } from "lucide-react";

import { getQuestion } from "@/components/wizard/questions";

import { ConversationPhase } from "shared/types";
import {
  expertPanelVar,
  applicationPageVar,
  Pages,
  isAuthenticatedVar,
  showReportVar,
  messagesVar,
} from "@/variables/globalVar";

import { useLogout } from "shared/apiHooks";
import { useAccount } from "@/hooks/useAccount";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from "shared/components";
import { PanelVersionsDropdown } from "@/components/PanelVersionsDropdown";
import { HelpMessage } from "@/components/HelpMessage";
import { LocalisedLogo } from "@/components/LocalisedLogo";
import { RestartDialog } from "./RestartDialog";

const allowChoosePanelVersion = import.meta.env
  .VITE_REACT_ALLOW_CHOOSE_PANEL_VERSION;

const isDevOrDojo = ["dev", "dojo"].includes(import.meta.env.VITE_REACT_ENV);

const REACT_ENV = import.meta.env.VITE_REACT_ENV;

export function Header() {
  const { conversation } = useAccount();

  const [isAuthenticated, setIsAuthenticatedVar] = useAtom(isAuthenticatedVar);
  const applicationPage = useAtomValue(applicationPageVar);
  const expertPanel = useAtomValue(expertPanelVar);
  const showReport = useAtomValue(showReportVar);
  const [messages, setMessagesVar] = useAtom(messagesVar);

  const [restartDialogOpen, setRestartDialogOpen] = useState(false);

  const { mutate } = useLogout({
    onSuccess: () => {
      console.log("<Header> authLogoutList() onSuccess()");
      setIsAuthenticatedVar(false);
    },
    onError: (error) => {
      console.error("<Header> authLogoutList() onError()", error);
    },
  });

  const handleClickLogout = () => {
    mutate();
  };

  return (
    <header className="border-b-interactiveBorder flex items-center justify-between border-b bg-[#F3F2F0] py-3 pl-4 pr-3 md:h-[60px] md:py-0 md:pl-8 md:pr-9">
      <div className="relative flex h-[32.51px] w-[73.19px] cursor-pointer items-center justify-center md:h-[36.57px] md:w-[82.34px]">
        <LocalisedLogo />

        {REACT_ENV && REACT_ENV !== "prod" ? (
          <span className="text-textLink absolute right-[-35px] top-[-4px] text-xs font-bold">
            ({REACT_ENV})
          </span>
        ) : null}
      </div>

      <div className="flex flex-row">
        {/*{isDevOrDojo ? (*/}
        {/*  <div className="mr-6 flex gap-3">*/}
        {/*    <Button*/}
        {/*      onClick={() => {*/}
        {/*        const newMessage = getQuestion(*/}
        {/*          0,*/}
        {/*          conversation?.id || "",*/}
        {/*          messages.length,*/}
        {/*          "CHOICES_QUESTION",*/}
        {/*        );*/}
        {/*        // @ts-expect-error inserting custom message type*/}
        {/*        setMessagesVar((prev) => [...prev, newMessage]);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      1*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      onClick={() => {*/}
        {/*        const newMessage = getQuestion(*/}
        {/*          1,*/}
        {/*          conversation?.id || "",*/}
        {/*          messages.length,*/}
        {/*          "CHOICES_QUESTION",*/}
        {/*        );*/}
        {/*        // @ts-expect-error inserting custom message type*/}
        {/*        setMessagesVar((prev) => [...prev, newMessage]);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      2*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      onClick={() => {*/}
        {/*        const newMessage = getQuestion(*/}
        {/*          2,*/}
        {/*          conversation?.id || "",*/}
        {/*          messages.length,*/}
        {/*          "CHOICES_QUESTION",*/}
        {/*        );*/}
        {/*        // @ts-expect-error inserting custom message type*/}
        {/*        setMessagesVar((prev) => [...prev, newMessage]);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      3*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      onClick={() => {*/}
        {/*        const newMessage = getQuestion(*/}
        {/*          3,*/}
        {/*          conversation?.id || "",*/}
        {/*          messages.length,*/}
        {/*          "TREE_DIAMETER_QUESTION",*/}
        {/*        );*/}
        {/*        // @ts-expect-error inserting custom message type*/}
        {/*        setMessagesVar((prev) => [...prev, newMessage]);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      4*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      onClick={() => {*/}
        {/*        const newMessage = getQuestion(*/}
        {/*          4,*/}
        {/*          conversation?.id || "",*/}
        {/*          messages.length,*/}
        {/*          "TREE_DISTANCE_QUESTION",*/}
        {/*        );*/}
        {/*        // @ts-expect-error inserting custom message type*/}
        {/*        setMessagesVar((prev) => [...prev, newMessage]);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      5*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*) : null}*/}

        {isAuthenticated &&
        allowChoosePanelVersion === "true" &&
        ((applicationPage === Pages.App && expertPanel) ||
          applicationPage === Pages.Address) ? (
          <PanelVersionsDropdown
            className="mr-6"
            disabled={applicationPage === Pages.App}
          />
        ) : null}

        {isAuthenticated && applicationPage === Pages.App ? (
          <Dialog open={restartDialogOpen} onOpenChange={setRestartDialogOpen}>
            <DialogTrigger
              tabIndex={0}
              className="text-actionDefault mr-2 flex flex-row items-center justify-between px-2 py-1.5 text-xs leading-[14px] md:mr-2.5 md:px-3"
            >
              <RotateCcw className="mr-1 h-[16px] w-[16px]" />
              Restart
            </DialogTrigger>
            <RestartDialog
              conversationId={conversation?.id || ""}
              conversationPhase={
                (conversation?.phase || "") as ConversationPhase
              }
              onOpenChange={setRestartDialogOpen}
              showReport={showReport}
            />
          </Dialog>
        ) : null}

        <Dialog>
          <DialogTrigger
            tabIndex={0}
            className="border-actionBorder bg-interfaceWhite text-actionDefault rounded-5xl flex cursor-pointer flex-row items-center justify-between border px-3 py-1.5 text-xs leading-[14px]"
          >
            <HandIcon className="mr-1 h-[16px] w-[16px]" />
            Help
          </DialogTrigger>
          <DialogContent
            className="max-w-[560px] rounded-3xl border-0 p-0"
            hideCloseButton
          >
            <div className="bg-interfaceWhite flex flex-col gap-[16px] rounded-[20px] p-[24px] shadow-sm md:px-[60px] md:py-[48px]">
              <DialogClose className="absolute right-6 top-6">
                <X className="text-textPrimary h-[24px] w-[24px]" />
              </DialogClose>
              <h2 className="text-textPrimary flex cursor-pointer items-center gap-2 text-[28px] font-semibold leading-[1.2]">
                <HandIcon className="text-textPrimary h-[24px] w-[24px]" />
                Help
              </h2>
              <div className="text-textPrimary text-lg leading-[1.2222]">
                <HelpMessage />
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {isAuthenticated ? (
          <button
            className="bg-interfaceWhite text-actionDefault rounded-5xl border-actionBorder ml-3 flex cursor-pointer flex-row items-center justify-between border px-3 py-1.5 text-xs leading-[14px]"
            onClick={handleClickLogout}
          >
            <LogOut className="mr-1 h-[16px] w-[16px]" />
            Log out
          </button>
        ) : null}
      </div>
    </header>
  );
}
